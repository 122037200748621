<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 class="text-center">{{role.name}} Permission</h4>
    <hr/>
    <div class="permission-container">
        <div v-for="(permissions, groupName) in data" :key="groupName" class="group">
            <h5 class="card-title">{{ groupName }}</h5>
            <div class="permissions-grid">
                <div v-for="(permission, index) in permissions" :key="permission.id" class="permission-item">
                    <div class="form-check">
                        <label class="form-check-label">
                        <input
                            class="checkbox form-check-input"
                            type="checkbox"
                            :id="permission.id"
                            :checked="permission.is_given"
                            @change="togglePermission(groupName, index)"
                        >{{ permission.name }}
                            <i class="input-helper"></i>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div class="row">
        <div class="col-3 mt-4 mb-4">
        </div>
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateRoleAPI()">Close</button>
        </div>
        <div class="col-3 mt-4 mb-4">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";

export default {
  data() {
    return {
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      role: null,
      typeform:{
        name: '',
        description: ''
      }
    };
  },
  validations: {
      typeform: {
        name:{required},
        description:{required},
      }
  },
  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-dialog-role--permission', (payload) => {
      this.data = payload.data;
      this.role = payload.role;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
  },
  methods: {
    ...mapActions("hospital",["assignPermissionRole"]),

    addUpdateRoleAPI() {
       this.emitResult(true);
    },

    togglePermission(groupName, index) {
      // Toggle the is_given value
      this.data[groupName][index].is_given = !this.data[groupName][index].is_given;
      var bodyFormData = new FormData();
      bodyFormData.append('role_id', this.role.id)
      bodyFormData.append('permission_id', this.data[groupName][index].id)
      this.assignPermissionRole(bodyFormData).then((response) => {
          this.is_btn_spinner = false
          if (response.response_code == 200) {
            this.$toasted.success(response.message, {duration: 2000,});
          } else {
            this.data[groupName][index].is_given = !this.data[groupName][index].is_given;
            this.$toasted.error(response.message, {duration: 2000,});
          }
      });
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('dialog-result-permission', result);
      this.showDialog = false;
    },
    resetData() {
      // Reset dialog fields
      this.typeform.name = '';
      this.typeform.description = '';
    },
    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.name = this.data.name;
          this.typeform.description = this.data.description;
      }
    },
    isForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    }
  }
};
</script>


<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>