<template>
<div>
  <nav-bar></nav-bar>
  <div class="container-fluid page-body-wrapper">
  <side-bar></side-bar>
  <!-- partial -->
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row align-items-center">
        <div class="col-12 col-md-4 mb-0 mb-md-0 text-center text-md-start"> <!-- Center text on small screens -->
              <div class="page-header">
                <h4 class="page-title">Role List</h4>
              </div>
          </div>
          <div class="col-lg-2 ms-auto">
            <button class="col-lg-12 btn btn-gradient-primary btn-fw" @click=openAddUpdateRoleDialog(null)>Add Role</button>
          </div>
      </div>
      </br>

      <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body nw-opd">
                 <div class="row">
                    <div class="col-lg-4 mb-lg-0 mb-2">
                        <input placeholder="Search" v-model="searchtext" class="form-control" type="search" name="searchrole" id="searchdoctor" autocomplete="off" v-on:keyup="getRoleList(1)">
                    </div>
                    <div class="col-lg-1"/>
                    <div class="col-lg-5"/>
                 </div>
              <div class="table-responsive">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Role</th>
                        <th>Description</th>
                        <th>Created at</th>
                        <th>Updated at</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(role,i) in roles" :key="i">
                        <td>{{role.id}}</td>
                        <td>{{role.name}}</td>
                        <td>{{role.description}}</td>
                        <td>{{moment(role.created_at).format('DD MMM YYYY, HH:mm')}}</td>
                        <td>{{moment(role.updaed_at).format('DD MMM YYYY, HH:mm')}}</td>
                        <td>
                              <button v-if ="role.id != 1" type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="openAddUpdateRoleDialog(role)"><i class="mdi mdi-tooltip-edit"></i></button>
                              &nbsp
                              <button v-if ="role.id != 1" type="button" class="btn btn-gradient-primary btn-rounded btn-icon" @click="getPermissionAPI(role)"><i class="mdi mdi-lock-open"></i></button>
                              &nbsp
                              <button v-if ="role.id != 1" type="button" @click="deleteRoleAPI(role.id, role.name)" class="btn btn-outline-secondary btn-rounded btn-icon"><i class="mdi mdi-delete"></i></button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
              </div>
              <div v-if="is_API_call_running" class="text-center mt-5">
                <b-spinner label=""></b-spinner>
              </div>
              <div class="row" v-if="!is_API_call_running && roles.length == 0" >
                  <div class="col-lg-12 text-center">
                    <br/><br/><br/>
                    <small class="text-muted">{{ errorMessage }}</small>
                    <br/><br/><br/><br/>
                  </div>
              </div>
              <div class="b-pagination-outer" v-if="listPages > 1">
                <ul id="border-pagination">
                  <li><a href="javascript:void(0)" @click="previewClick()">«</a></li>
                  <li v-for="page in listPages" :key="page"><a href="javascript:void(0)" @click="getRoleList(page)" :class="(activePage && activePage==page)?'active':''">{{page}}</a></li>
                  <li><a href="javascript:void(0)" @click="nextClick()">»</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</div>
    <RoleComponent/>
    <RolePermissionComponent/>
</div>
</template>
<script>
import NavBar from "@/components/NavBar.vue";
import Footer from "@/components/Footer.vue";
import SideBar from "@/components/SideBar.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import DateRangePicker from 'vue2-daterange-picker'
import string from "../../constant/strings.js"
import Swal from 'sweetalert2'
import { EventBus } from '@/event-bus';
import RoleComponent from './component_role.vue';
import RolePermissionComponent from './component_role_permission.vue';

export default {
  name:'Roles',
  title: string.PAGE_TITLE_ROLE,
  components: {
      NavBar,
Footer,
      SideBar,
     DateRangePicker,
     Multiselect,
     RoleComponent,
     RolePermissionComponent
  },
  data() {
    return {
      moment,
      roles:[],
      is_API_call_running:false,
      listPages:0,
      activePage:1,
      searchtext:'',
      myTimer:null,
      errorMessage : "No Data Found",
    }
  },
  mounted() {
    this.getRoleList(this.activePage);
  },
  created() {
    // Listen for the dialog result
    EventBus.$on('dialog-result', (result) => {
        this.dialogResult = result;
        if(this.dialogResult.status) {
            this.getRoleList(1);
        }
    });
    EventBus.$on('dialog-result-permission', (result) => {

    });
  },
  methods:{
    ...mapActions("hospital",["getRole", "deleteRole", "getPermission"]),

    getRoleList(page){
      this.activePage = page;
      clearTimeout(this.myTimer)
      this.myTimer = setTimeout(() => {
        this.is_API_call_running = true
        this.roles = [];
        let queryParams = {
            filter_value : this.searchtext,
            page : page,
        }
        this.getRole(queryParams).then((response) => {
            this.is_API_call_running = false
            if (response.response_code == 200) {
                this.roles = response.data;
                this.listPages = response.no_of_pages;
            }else{
              this.errorMessage = response.message;
            }
        });
      }, 500)
    },

    getPermissionAPI(role){
      this.permissions = [];
      let queryParams = {
          role_id : role.id,
      }
      this.getPermission(queryParams).then((response) => {
            if (response.response_code == 200) {
                this.permissions = response.data;
                const payload = { data: this.permissions, role : role};
                EventBus.$emit('open-dialog-role--permission', payload); // Trigger the dialog opening
            }else{
              this.errorMessage = response.message;
            }
        });
    },

    previewClick(){
          this.activePage--;
          if(this.activePage >= 1){
            this.getRoleList(this.activePage);
          }else{
            this.activePage = 1;
          }
        },

    nextClick(){
       this.activePage++;
       if(this.activePage <= this.listPages){
            this.getRoleList(this.activePage);
       }else{
            this.activePage = this.listPages;
       }
    },

    deleteRoleAPI(id, name, index) {
        Swal.fire({
          title: string.LABEL_ARE_YOU_SURE,
          text: string.ALERT_MESSAGE_DELETE_PRE + name + string.ROLE,
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!"
        }).then((result) => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            bodyFormData.append('role_id', id);
            this.deleteRole(bodyFormData).then((response) => {
                if (response.response_code == 200) {
                    this.$toasted.success(response.message, {duration: 2000,});
                    for (let i = 0; i <= this.roles.length;i++){
                        if(id == this.roles[i].id) {
                            this.roles.splice(i, 1);
                            break;
                        }
                    }
                } else{
                    this.$toasted.error(response.message, {duration: 2000,});
                }
            });
          }
        });
    },

    openAddUpdateRoleDialog(role) {
        const payload = { data: role };
        EventBus.$emit('open-dialog', payload); // Trigger the dialog opening
    },

  }
}
</script>
<style src="vue2-daterange-picker/dist/vue2-daterange-picker.css"></style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>