<template>
  <div v-if="showDialog" class="dialog">
    <div class="dialog-content">
      <h4 v-if="isForUpdate()" class="text-center">Update Role</h4>
      <h4 v-else class="text-center">Add Role</h4>
      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Role Name</label>
            <input type="text" class="form-control" v-model="$v.typeform.name.$model" :class="{ 'is-invalid':  $v.typeform.name.$error  }" name="role_name" placeholder="Role Name"/>
            <div v-if="$v.typeform.name.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.name.required">Please enter role name</span>
            </div>
        </div>
      </div>
      <div class="form-group row mb-2">
        <div class="col-sm-12">
            <label class="col-form-label">Description</label>
            <textarea rows="5" class="form-control" v-model="$v.typeform.description.$model" :class="{ 'is-invalid':  $v.typeform.description.$error  }" name="role_description" placeholder="Role Description"/>
            <div v-if="$v.typeform.description.$error" class="invalid-feedback">
                <span v-if="!$v.typeform.description.required">Please enter descriptions</span>
            </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6 mt-4 mb-4">
          <button class="btn btn-outline-secondary btn-fw w-100" @click="emitResult(false)">Close</button>
        </div>
        <div class="col-6 mt-4 mb-4">
          <b-button v-if="is_btn_spinner" variant="primary" disabled>
            <b-spinner small type="grow"></b-spinner>Loading...
          </b-button>
          <div v-else>
            <button v-if="isForUpdate()" class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateRoleAPI()">Update Role</button>
            <button v-else class="btn btn-gradient-primary btn-fw w-100" @click="addUpdateRoleAPI()">Add Role</button>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { EventBus } from '@/event-bus';
import { required, numeric } from "vuelidate/lib/validators";
import { mapActions} from "vuex";

export default {
  data() {
    return {
      showDialog: false,
      is_btn_spinner: false,
      data: null,
      typeform:{
        name: '',
        description: ''
      }
    };
  },
  validations: {
      typeform: {
        name:{required},
        description:{required},
      }
  },
  created() {
    // Listen for the event to open the dialog
    EventBus.$on('open-dialog', (payload) => {
      this.data = payload.data;
      this.showDialog = true;
      this.resetData()
      this.setPreviousData()
    });
  },
  methods: {
    ...mapActions("hospital",["addRole", "updateRole"]),

    addUpdateRoleAPI() {
       this.$v.$touch();
       if (!this.$v.typeform.$invalid) {
           var bodyFormData = new FormData();
           bodyFormData.append('name', this.typeform.name)
           bodyFormData.append('description', this.typeform.description)
           if (this.isForUpdate()) {
                bodyFormData.append('role_id', this.data.id)
                this.is_btn_spinner = true
                this.updateRole(bodyFormData).then((response) => {
                    this.is_btn_spinner = false
                    if (response.response_code == 200) {
                        this.emitResult(true);
                    } else {
                        this.$toasted.error(response.message, {duration: 2000,});
                    }
                });
           } else {
               this.is_btn_spinner = true
               this.addRole(bodyFormData).then((response) => {
                    this.is_btn_spinner = false
                    if (response.response_code == 200) {
                        this.emitResult(true);
                    } else {
                        this.$toasted.error(response.message, {duration: 2000,});
                    }
               });
           }


       }
    },

    emitResult(actionDone) {
      // Emit the result when closing the dialog
      const result = { status: actionDone};
      EventBus.$emit('dialog-result', result);
      this.showDialog = false;
    },
    resetData() {
      // Reset dialog fields
      this.typeform.name = '';
      this.typeform.description = '';
    },
    setPreviousData() {
      if (this.data && this.data != ''){
          this.typeform.name = this.data.name;
          this.typeform.description = this.data.description;
      }
    },
    isForUpdate() {
        if (this.data && this.data != ''){
            return true
        } else {
            return false
        }
    }
  }
};
</script>


<style>
/* Full-screen overlay */
.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Dialog content with responsive adjustments */
.dialog-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 70vh; /* Ensure the dialog doesn't exceed viewport height */
  overflow-y: auto;  /* Scroll content if height exceeds viewport */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
}

/* Media queries for larger screens */
@media (min-width: 576px) {
  .dialog-content {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .dialog-content {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .dialog-content {
    width: 30%;
  }
}
</style>